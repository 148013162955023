$mobile-size: 950px;

.layout {
    overflow: hidden;
}

main {
    width: 100%;
    height: 100vh;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
}

.content {
    display: block;
    position: absolute;
    height:auto;
    bottom:0;
    top:0;
    left:0;
    right:0;
    margin-top:20px;
    margin-bottom:20px;
    margin-right:80px;
    margin-left:80px;
    overflow: hidden
}

.content-flex {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.main-section {
    padding: 0 20px;
    max-height: 85vh;
    overflow: scroll;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    width: 60%;
    max-width: 1000px;
}

.loading-background {
    display: flex;
    justify-content: center;
    background-color: rgba($color: #6c626993, $alpha: 0.4);
}

.logo-with-loading {
    width: 150px;
}

.loading-annimation {
    max-width: 400px;
    opacity: 0.7;
}

.hide {
    display: none;
}

@media screen and (max-width: $mobile-size) {

    .content {
        margin-top:20px;
        margin-bottom:20px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .content-flex {
        flex-direction: column;
        padding: 0;
    }

    .main-section {
        padding: 20px;
        width: 90%;
        max-height: 65vh;
    }

    .loading-annimation {
        max-width: 300px;
    }

}