$mobile-size: 950px;

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
}

.about-more {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: $mobile-size) {

}