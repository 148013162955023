$mobile-size: 950px;

.contact {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.socials {
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
        cursor: pointer;
        pointer-events: auto;
    }
}

@media screen and (max-width: $mobile-size) {
    
}