$mobile-size: 950px;

.work {
    display: flex;
    flex-direction: column;

    h3 {
        margin: 20px 0 5px 0;
        text-decoration: underline;
    }
}

@media screen and (max-width: $mobile-size) {
    
}
