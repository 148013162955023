$mobile-size: 950px;
$name-colour: #013A20;
$nav-colour: #304E30;
$nav-colour-secondary: #013A20;

html, body {
    height: 100vh;
    overflow: hidden;
}

body {
    background: rgb(255, 255, 255);
    color: white;
    // font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    // letter-spacing: 2px;
}

html a {
    cursor: pointer;
    pointer-events: auto;
}

.page {
    // max-width: 1000px;
    margin: 40px auto 0;
    padding: 5px;
}

p {
    margin-bottom: 10px;
}

h1, h2 {
  letter-spacing: 10px;
  &::after{
      content: "\a";
      white-space: pre;
  }
}

p::after{
    content: "\a";
    white-space: pre;
}

.loader {
    width: 100px;
    height: 100px;
    display: flex;
    margin: 100px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;

    &.theme-color::before {
        border: 5px solid rgba($nav-colour, 0.3);
    }
}

.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

@media screen and (max-width: $mobile-size) {
    .page {
        max-width: unset;
        margin: 0 auto;
    }
}