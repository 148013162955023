$mobile-size: 950px;

.header {
    margin-left: 70px;
}

nav {
    display: flex;
    flex-direction: column;
    gap: 1px;

    button {
        cursor: pointer;
        pointer-events: auto;
        padding: 5px;
        // font-size: 30px;
        font-size: 35px;
        font-weight: 600;
        line-height: 35px;
    }
}

@media screen and (max-width: $mobile-size) {

    .header {
        margin-left: 0;
        margin-bottom: 30px;
        padding: 0 20px;
    }

    nav button {
        // font-size: 25px;
        font-size: 30px;
    }

}